
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap px-4 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.account_transfers.edit_path">
                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                {{ item.title }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            <form @submit.prevent="updateAccountTransfers" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="accounts" v-model="account_transfers.sender_id" dense  outlined item-text="account_name"
                                    item-value="account_id" :return-object="false" :label="$store.getters.language.data.account_transfers.sender">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="accounts" v-model="account_transfers.receiver_id" dense  outlined item-text="account_name"
                                item-value="account_id" :return-object="false" :label="$store.getters.language.data.account_transfers.receiver">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="account_transfers.account_transfer_date" type="date" :label="$store.getters.language.data.account_transfers.account_transfer_date" dense
                            class="mx-1"  outlined required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="account_transfers.account_transfer_amount" type="text" :label="$store.getters.language.data.account_transfers.account_transfer_amount" dense
                            class="mx-1"  outlined required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="users" v-model="account_transfers.user_id" dense  outlined item-text="user_name"
                                item-value="user_id" :return-object="false" :label="$store.getters.language.data.account_transfers.user_name" :disabled="$store.getters.user.user_role != 'admin'">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="account_transfers.account_transfer_note" type="textarea" :label="$store.getters.language.data.account_transfers.account_transfer_note" dense
                            class="mx-1" outlined>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="success" type="submit" :disabled="buttonDisable">{{$store.getters.language.data.account_transfers.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/account_transfers.request.js'
    export default {
        data() {
            return {
                account_transfers: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            accounts(){
                return this.$store.getters.accounts_list
            },
            users(){
                return this.$store.getters.users_list
            },
            buttonDisable() {
                return !this.account_transfers.account_transfer_amount ||
                    !this.account_transfers.sender_id ||
                    !this.account_transfers.receiver_id ||
                    !this.account_transfers.account_transfer_date ||
                    !this.account_transfers.user_id 
            }
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneAccountTransfers()
        },
        methods: {
            getOneAccountTransfers() {
                this.loading = true
                requests.getOneAccountTransfers(this.id).then(r =>{
                    if (r.status == 200) {
                        this.account_transfers = r.data.row
                        this.account_transfers.account_transfer_date = new Date(r.data.row.account_transfer_date).toISOString().split('T')[0];
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateAccountTransfers() {
                this.loading = true
                requests.updateAccountTransfers(this.id,this.account_transfers).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.update_success,
                            color: 'success'
                        }
                        this.loading = false
                        this.$router.push('/account_transfers-list/' + this.account_transfers.sender_id)
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    